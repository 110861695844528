var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "발의 상세" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    codeGroupCd: "MOC_TYPE_CD",
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "mocTypeCd",
                    label: "변경구분",
                  },
                  model: {
                    value: _vm.moc.mocTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocTypeCd", $$v)
                    },
                    expression: "moc.mocTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "LBLMOCNO",
                    name: "mocNo",
                  },
                  model: {
                    value: _vm.moc.mocNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocNo", $$v)
                    },
                    expression: "moc.mocNo",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    codeGroupCd: "MOC_LEVEL_CD",
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "mocWriteLevelCd",
                    label: "등급(작성)",
                  },
                  model: {
                    value: _vm.moc.mocWriteLevelCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocWriteLevelCd", $$v)
                    },
                    expression: "moc.mocWriteLevelCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    codeGroupCd: "MOC_LEVEL_CD",
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "mocConfirmLevelCd",
                    label: "등급(검토)",
                  },
                  model: {
                    value: _vm.moc.mocConfirmLevelCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocConfirmLevelCd", $$v)
                    },
                    expression: "moc.mocConfirmLevelCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "LBLTITLE",
                    name: "mocTitle",
                  },
                  model: {
                    value: _vm.moc.mocTitle,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocTitle", $$v)
                    },
                    expression: "moc.mocTitle",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.moc.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "plantCd", $$v)
                    },
                    expression: "moc.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "요청부서/이름",
                    name: "request",
                  },
                  model: {
                    value: _vm.initiative,
                    callback: function ($$v) {
                      _vm.initiative = $$v
                    },
                    expression: "initiative",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    range: true,
                    label: "변경 작업 기간",
                    name: "mocPeriod",
                  },
                  model: {
                    value: _vm.moc.mocPeriod,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocPeriod", $$v)
                    },
                    expression: "moc.mocPeriod",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    data: _vm.moc,
                    deptValue: "mocActionDeptCd",
                    type: "dept_user",
                    label: "변경실행책임자",
                    name: "mocActionResponsibilityUserId",
                  },
                  model: {
                    value: _vm.moc.mocActionResponsibilityUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocActionResponsibilityUserId", $$v)
                    },
                    expression: "moc.mocActionResponsibilityUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    data: _vm.moc,
                    deptValue: "mocCompleteCheckDeptCd",
                    type: "dept_user",
                    label: "변경완료확인자",
                    name: "mocCompleteCheckUserId",
                  },
                  model: {
                    value: _vm.moc.mocCompleteCheckUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocCompleteCheckUserId", $$v)
                    },
                    expression: "moc.mocCompleteCheckUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-tag", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    clickable: true,
                    itemText: "equipmentName",
                    itemValue: "check",
                    label: "관련설비",
                    name: "equipments",
                  },
                  on: { clickTag: _vm.clickTag },
                  model: {
                    value: _vm.moc.equipments,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "equipments", $$v)
                    },
                    expression: "moc.equipments",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "변경계획에 대한 공정 및 설계의 기술근거",
                    name: "technicalBasisProcessDesign",
                  },
                  model: {
                    value: _vm.moc.technicalBasisProcessDesign,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "technicalBasisProcessDesign", $$v)
                    },
                    expression: "moc.technicalBasisProcessDesign",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "변경의 개요와 의견",
                    name: "mocSummary",
                  },
                  model: {
                    value: _vm.moc.mocSummary,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocSummary", $$v)
                    },
                    expression: "moc.mocSummary",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "공정안전 확보를 위한 대책",
                    name: "processSatefySecureMeasures",
                  },
                  model: {
                    value: _vm.moc.processSatefySecureMeasures,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "processSatefySecureMeasures", $$v)
                    },
                    expression: "moc.processSatefySecureMeasures",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "안전성에 필요한 사항 및 신뢰성 향상 효과",
                    name: "safetyReliabilityImprovementEffect",
                  },
                  model: {
                    value: _vm.moc.safetyReliabilityImprovementEffect,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.moc,
                        "safetyReliabilityImprovementEffect",
                        $$v
                      )
                    },
                    expression: "moc.safetyReliabilityImprovementEffect",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "관련근거법령",
                    name: "relatedLaws",
                  },
                  model: {
                    value: _vm.moc.relatedLaws,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "relatedLaws", $$v)
                    },
                    expression: "moc.relatedLaws",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-checkbox", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    isArray: false,
                    codeGroupCd: "MOC_BENEFIT_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "변경 후 기대효과",
                    name: "mocBenefitCds",
                  },
                  model: {
                    value: _vm.moc.mocBenefitCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocBenefitCds", $$v)
                    },
                    expression: "moc.mocBenefitCds",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "팀장의견",
                    name: "leaderOpinion",
                  },
                  model: {
                    value: _vm.moc.leaderOpinion,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "leaderOpinion", $$v)
                    },
                    expression: "moc.leaderOpinion",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }